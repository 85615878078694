import React from "react";
import ReactGA from "react-ga4";
import { BackgroundImage } from "react-image-and-background-image-fade";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import PostApiCall from "../../components/helper/Api";

ReactGA.initialize([
  {
    trackingId: "G-2VDZ0VM2P4",
  },
]);

class CustomPackaginBoxOnline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      onlineStoreBtn: false,
      VerticleData: [],
      loading: true,
    };
  }
  componentDidMount() {
    PostApiCall.postRequest(
      {
        whereClause: "where fld_status = 'Active'",
        recordCount: "*",
      },
      "GetProductMaster"
    ).then((resultdes) =>
      resultdes.json().then((obj) => {
        this.setState({
          VerticleData: obj.data,
          loading: false,
        });
      })
    );
  }
  render() {
    const { VerticleData, loading } = this.state;
    return (
      <div class="container-fluid px-md-4 px-3">
        <div class="row">
          <div class="col-md-12">
            <div class="section-title text-center mb-3">
              <h2 className="mb-0 pb-0">Choose your custom packaging box</h2>
            </div>
          </div>
        </div>

        {/* ****** Desktop View ******** */}
        <div className="grid-container desktop-online-custom-box">
          {loading
            ? Array(6)
              .fill()
              .map((_, i) => (
                <div className="grid-item" key={i}>
                  <Skeleton height={212} width={243} />
                  <Skeleton
                    height={30}
                    width={`60%`}
                    style={{ marginTop: "10px" }}
                  />
                </div>
              ))
            : VerticleData.map((data, i) => (
              <div className="grid-item" key={i}>
                <div
                  className="item-content px-0"
                  onClick={() => {
                    this.setState({
                      activeproduct: data.fld_productid,
                    });
                  }}
                  onMouseOver={() => {
                    this.setState({
                      showShopNowbutton: data.fld_productid,
                    });
                  }}
                  onMouseLeave={() => {
                    this.setState({
                      showShopNowbutton: "",
                    });
                  }}
                >
                  <div
                    className={
                      this.state.activeproduct === data.fld_productid
                        ? "product-cat-box-active-border product-cat-box mx-1 mb-2"
                        : "product-cat-box mx-1 mb-2"
                    }
                  >
                    <div className="product-cat-box-txt">
                      <p className="product-cat-box-name-txt custom-lg-online-heading product-content-align">
                        {data.fld_itemname}
                      </p>
                    </div>
                    <div className="product-cat-box-img">
                      <a
                        onClick={() => {
                          ReactGA.event({
                            category: "ProductClick-Home",
                            action: data.fld_itemname,
                            label: data.fld_itemname, // optional
                            nonInteraction: true, // optional, true/false
                            transport: "xhr", // optional, beacon/xhr/image
                          });
                          window.location.href = `${process.env.PUBLIC_URL}/p/${data.fld_route}`;
                        }}
                      >
                        <BackgroundImage
                          src={data.fld_itemimage}
                          width="243px"
                          height="212px"
                          isResponsive
                          className="img-fluid"
                          alt="Retail Food Packaging"
                          style={{
                            backgroundSize: "cover",
                            backgroundPosition: "center top",
                          }}
                          lazyLoad
                        />
                      </a>
                    </div>
                    {/* <div className="product-cat-box-txt">
                    <button
                      type="button"
                      className="btn btn-primary centered"
                      style={{
                        display: this.state.showShopNowbutton === data.fld_productid ? "block" : "none",
                        background: "#9b9c9e",
                        border: "1px solid #9b9c9e",
                      }}
                      onClick={() => {
                        window.location.href = `${process.env.PUBLIC_URL}/customizeyourbox/${data.fld_productid}`;
                      }}
                    >
                      Shop Now
                    </button>
                  </div> */}
                    <a
                      className="btn shopnowbtn2 shop-phn-btn custom-phn-online-btn"
                      href={`${process.env.PUBLIC_URL}/p/${data.fld_route}`}
                    >
                      Customize your order
                    </a>
                  </div>
                </div>
              </div>
            ))}
        </div>

        {/* ****** Tab and mobile view ******* */}
        <div className="row phn-online-custom-box">
          {loading
            ? Array(12)
              .fill()
              .map((_, i) => (
                <div className="grid-item sticker-phn-skelton" key={i}>
                  <Skeleton className="sticker-online-size" />
                </div>
              ))
            : VerticleData.map((data, i) => (
              <div className="col-6 col-sm-4 px-0">
                <div
                  class="px-0"
                  onClick={() => {
                    this.setState({
                      activeproduct: data.fld_productid,
                    });
                  }}
                  onMouseOver={() => {
                    this.setState({
                      showShopNowbutton: data.fld_productid,
                    });
                  }}
                  onMouseLeave={() => {
                    this.setState({
                      showShopNowbutton: "",
                    });
                  }}
                >
                  <div
                    className={
                      this.state.activeproduct == data.fld_productid
                        ? "product-cat-box-active-border product-cat-box mx-1 mb-2"
                        : "product-cat-box mx-1 mb-2"
                    }
                  >
                    <div class="product-cat-box-txt">
                      <p className="product-cat-box-name-txt custom-phn-online-heading product-content-align">
                        {data.fld_itemname}
                      </p>
                    </div>
                    <div class="product-cat-box-img">
                      <a
                        onClick={() => {
                          ReactGA.event({
                            category: "ProductClick-Home",
                            action: data.fld_itemname,
                            label: data.fld_itemname, // optional
                            nonInteraction: true, // optional, true/false
                            transport: "xhr", // optional, beacon/xhr/image
                          });
                          window.location.href = `${process.env.PUBLIC_URL}/p/${data.fld_route}`;
                        }}
                      >
                        <BackgroundImage
                          src={data.fld_itemimage}
                          width="243px"
                          height="212px"
                          isResponsive
                          className="img-fluid"
                          alt="Retail Food Packaging"
                          style={{
                            backgroundSize: "cover",
                            backgroundPosition: "center top",
                          }}
                          lazyLoad
                        />
                      </a>
                    </div>
                    {/* <div class="product-cat-box-txt">
                    <button
                      type="button"
                      class="btn btn-primary centered"
                      style={{
                        display:
                          this.state.showShopNowbutton == data.fld_productid
                            ? "block"
                            : "none",
                        background: "#9b9c9e",
                        border: "1px solid #9b9c9e",
                      }}
                      onClick={() => {
                        window.location.href = `${process.env.PUBLIC_URL}/customizeyourbox/${data.fld_productid}`;
                      }}
                    >
                      Shop Now
                    </button>
                  </div> */}
                    <a
                      className="btn shopnowbtn2 custom-phn-online-btn"
                      href={`${process.env.PUBLIC_URL}/p/${data.fld_route}`}
                    >
                      Customize your order
                    </a>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  }
}

export default CustomPackaginBoxOnline;
