import React from "react";
import { isMobile } from "react-device-detect";
import piologo from "../src/assets/image/pio-logo.png";
import Footer from "./Footer";
import Header from "./Header";
import MOQ from "./MOQ.PNG";
import Products from "./Products";
import MonoCartonForm from "./MonoCartonForm";

class MonoCartons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show : false,
      onlineStoreBtn: false,
    };
  }
  componentDidMount() {
    const store = this.context;
    {
      isMobile
        ? this.setState({
            onlineStoreBtn: true,
          })
        : this.setState({
            onlineStoreBtn: false,
          });
    }
  }
  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };
  render() {
    return (     
      <div>
        {console.log(this.state.show)}
        <div
          class="col-md-12 m-show online-ordering"
          id="onilne-store"
          style={{
            display: this.state.onlineStoreBtn == true ? "block" : "none",
          }}>
          <a
            class="mx-lg-3 btn shopnowbtn"
            // href="/ordercustomboxes"
            href="/onlinestore"
            style={{
              background: "#59c5d1",
              borderRadius: "3px",
              color: "white",
              width: "85%",
              boxShadow: "0px 0px 12px rgba(0,0,0,.5)",
              fontSize: "22px",
            }}>
            Online Store
          </a>
        </div>
        <Header></Header>
        <section class="page-banner">
          <div class="container-fluid px-md-4 px-3">
            <div class="row mt-4">
              <div class="col-md-12">
                <div class="top-breadcrumb">
                  <div className="w-100 text-center inner-pio-phn-logo justify-content-center d-md-none d-flex">
                    <img src={piologo} alt="pio-packaging" />
                  </div>

                  <ul>
                    <li class="breadcrumb-item">
                      <a href="http://piopackaging.com">Home</a>
                    </li>
                    <li class="breadcrumb-item">
                      <a href="http://piopackaging.com#products">Products</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Mono Cartons
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="section">
          <div class="quantity-icon position-fixed">
            <img src={MOQ} alt="" />
          </div>
          <div class="container-fluid px-md-4 px-3">
            <div class="row">
              <div class="col-md-12">
                <div class="section-title text-center mb-1">
                  {/* <h2 className="text-theme">Mono Cartons</h2> */}
                  <h2 className="mono-font-weight">Mono Cartons</h2>
                  <h5 className="text-dark font-weight-bold mb-0 my-2 mono-subheading-phone">
                    TAILORMADE PACKAGING SOLUTIONS FOR EVERY INDUSTRY
                  </h5>
                </div>
              </div>
              <div class="col-md-12">
                <div class="inner-page-cont mt-0">
                  <p>
                    At Pio Packaging, we provide top-notch custom mono cartons
                    for a wide range of industries, ensuring your products stand
                    out on the shelves. Our mono cartons are designed for
                    strength, durability, and visual appeal, making them the
                    ideal packaging solution for various product types.
                  </p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                {/* <div class="sub-section-title sub-section-title-black mb-1 mt-2">
                  <h2>Sample Products</h2>
                </div> */}
                <p className="text-center text-dark mb-4">
                  We offer mono cartons for numerous sectors, including:
                </p>
              </div>
            </div>
            {/* <div class="row">
              <div class="col-xl-3 col-lg-4 col-sm-6 mb-lg-0 mb-sm-4 mb-0 mono-margin-btn">
                <div class="product-item">
                  <div class="product-item-img">
                    <a
                      href="assets/img/mono-cartons/mono-cartons-02.PNG"
                      data-fancybox="images"
                    >
                      <img
                        class="img-fluid"
                        src="assets/img/mono-cartons/thumb02.jpg"
                        alt="Mono Cartons"
                      />

                      <div class="product-view-btn">
                        <i class="fa fa-eye"></i>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6 mb-lg-0 mb-sm-4 mb-0 mono-margin-btn">
                <div class="product-item">
                  <div class="product-item-img">
                    <a
                      href="assets/img/mono-cartons/mono-cartons-03.JPG"
                      data-fancybox="images"
                    >
                      <img
                        class="img-fluid"
                        src="assets/img/mono-cartons/thumb03.jpg"
                        alt="Mono Cartons"
                      />

                      <div class="product-view-btn">
                        <i class="fa fa-eye"></i>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6 mb-lg-0 mb-sm-4 mb-0 mono-margin-btn">
                <div class="product-item">
                  <div class="product-item-img">
                    <a
                      href="assets/img/mono-cartons/mono-cartons-04.PNG"
                      data-fancybox="images"
                    >
                      <img
                        class="img-fluid"
                        src="assets/img/mono-cartons/thumb04.jpg"
                        alt="Mono Cartons"
                      />

                      <div class="product-view-btn">
                        <i class="fa fa-eye"></i>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6 mb-lg-0 mb-sm-4 mb-0 mono-margin-btn">
                <div class="product-item">
                  <div class="product-item-img">
                    <a
                      href="assets/img/mono-cartons/mono-cartons-05.JPG"
                      data-fancybox="images"
                    >
                      <img
                        class="img-fluid"
                        src="assets/img/mono-cartons/thumb05.jpg"
                        alt="Mono Cartons"
                      />

                      <div class="product-view-btn">
                        <i class="fa fa-eye"></i>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div> */}
            <div class="row mono-cartons-products">
              <div class="col-xl-3 col-lg-4 col-sm-6 mb-lg-0 mb-sm-4 mb-0 mono-margin-btn">
                <a href="/p/chocolate-bar-box">
                  <div class="product-cat-box new-mono-item-card">
                    <div class="product-cat-box-img">
                      <img
                        class="img-fluid"
                        src="assets/img/mono-cartons/New-folder/CHOCOLATE-BOX.jpg"
                        alt="Chocolate Box Mono Cartons"
                      />
                    </div>
                    <div class="product-cat-box-txt px-2 pb-4">
                      <p>Chocolate Box</p>
                      <span>
                        Attractive and durable chocolate boxes for confectionery
                        brands, ensuring freshness & style.
                      </span>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6 mb-lg-0 mb-sm-4 mb-0 mono-margin-btn">
                <a href="/p/face-mask-box">
                  <div class="product-cat-box new-mono-item-card">
                    <div class="product-cat-box-img">
                      <img
                        class="img-fluid"
                        src="assets/img/mono-cartons/New-folder/face-mask-box.jpg"
                        alt="Face Mask Box Mono Cartons"
                      />
                    </div>
                    <div class="product-cat-box-txt px-2 pb-4">
                      <p>Face Mask Box</p>
                      <span>
                        Reliable face mask boxes that maintain hygiene standards
                        while promoting your brand.
                      </span>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6 mb-lg-0 mb-sm-4 mb-0 mono-margin-btn">
                <a href="/p/surgical-gloves-box">
                  <div class="product-cat-box new-mono-item-card">
                    <div class="product-cat-box-img">
                      <img
                        class="img-fluid"
                        src="assets/img/mono-cartons/New-folder/Gloves-Box.jpg"
                        alt="Gloves Box Mono Cartons"
                      />
                    </div>
                    <div class="product-cat-box-txt px-2 pb-4">
                      <p>Gloves Box</p>
                      <span>
                        Custom glove boxes for both industrial and medical uses,
                        offering a clean and protective packaging option.
                      </span>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6 mb-lg-0 mb-sm-4 mb-0 mono-margin-btn">
                <a href="/p/soap-box">
                  <div class="product-cat-box new-mono-item-card">
                    <div class="product-cat-box-img">
                      <img
                        class="img-fluid"
                        src="assets/img/mono-cartons/New-folder/SOAP-BOX.jpg"
                        alt="Soap Box Mono Cartons"
                      />
                    </div>
                    <div class="product-cat-box-txt px-2 pb-4">
                      <p>Soap Box</p>
                      <span>
                        Eco-friendly and stylish soap boxes for natural and
                        artisanal soap brands.
                      </span>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6 mb-lg-0 mb-sm-4 mb-0 mono-margin-btn">
                <a href="/p/health-mix-box">
                  <div class="product-cat-box new-mono-item-card">
                    <div class="product-cat-box-img">
                      <img
                        class="img-fluid"
                        src="assets/img/mono-cartons/New-folder/HEALTH-MIX-BOX.jpg"
                        alt="Health Mix Box Mono Cartons"
                      />
                    </div>
                    <div class="product-cat-box-txt px-2 pb-4">
                      <p>Health Mix Box</p>
                      <span>
                        Secure and attractive health mix boxes that keep your
                        health products safe while enhancing brand recognition.
                      </span>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6 mb-lg-0 mb-sm-4 mb-0 mono-margin-btn">
                <a href="/p/masala-packing-box">
                  <div class="product-cat-box new-mono-item-card">
                    <div class="product-cat-box-img">
                      <img
                        class="img-fluid"
                        src="assets/img/mono-cartons/New-folder/MASALA-BOX.jpg"
                        alt="Masala Box Mono Cartons"
                      />
                    </div>
                    <div class="product-cat-box-txt px-2 pb-4">
                      <p>Masala Box</p>
                      <span>
                        Flavor-preserving masala boxes for spice brands,
                        ensuring long-lasting freshness & vibrant packaging.
                      </span>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6 mb-lg-0 mb-sm-4 mb-0 mono-margin-btn">
                <a href="/p/tea-packing-box">
                  <div class="product-cat-box new-mono-item-card">
                    <div class="product-cat-box-img">
                      <img
                        class="img-fluid"
                        src="assets/img/mono-cartons/New-folder/tea-box.jpg"
                        alt="Tea Box Mono Cartons"
                      />
                    </div>
                    <div class="product-cat-box-txt px-2 pb-4">
                      <p>Tea Box</p>
                      <span>
                        Premium tea boxes that preserve the freshness and aroma
                        of your tea products.
                      </span>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6 mb-lg-0 mb-sm-4 mb-0 mono-margin-btn">
                <a href="javascript:void(0)" onClick={this.handleShow}>
                  <div class="product-cat-box new-mono-item-card">
                    <div class="product-cat-box-img">
                      <img
                        class="img-fluid"
                        src="assets/img/mono-cartons/New-folder/HAIR-OIL-BOX.jpg"
                        alt="Hair Oil Box Mono Cartons"
                      />
                    </div>
                    <div class="product-cat-box-txt px-2 pb-4">
                      <p>Hair Oil Box</p>
                      <span>
                        Custom-designed hair oil boxes to protect and showcase
                        your product’s quality and appeal.
                      </span>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6 mb-lg-0 mb-sm-4 mb-0 mono-margin-btn">
                <a href="javascript:void(0)" onClick={this.handleShow}>
                  <div class="product-cat-box new-mono-item-card">
                    <div class="product-cat-box-img">
                      <img
                        class="img-fluid"
                        src="assets/img/mono-cartons/New-folder/PERFUME-BOX.jpg"
                        alt="Perfume Box Mono Cartons"
                      />
                    </div>
                    <div class="product-cat-box-txt px-2 pb-4">
                      <p>Perfume Box</p>
                      <span>
                        Elegant perfume boxes that elevate the luxury feel of
                        your brand, ensuring your perfume packaging is both
                        stylish and secure.
                      </span>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6 mb-lg-0 mb-sm-4 mb-0 mono-margin-btn">
                <a href="javascript:void(0)" onClick={this.handleShow}>
                  <div class="product-cat-box new-mono-item-card">
                    <div class="product-cat-box-img">
                      <img
                        class="img-fluid"
                        src="assets/img/mono-cartons/New-folder/FACE-CREAM-BOX.jpg"
                        alt="Face Cream Box Mono Cartons"
                      />
                    </div>
                    <div class="product-cat-box-txt px-2 pb-4">
                      <p>Face Cream Box</p>
                      <span>
                        High-quality face cream boxes tailored for cosmetics
                        brands to highlight premium skincare products.
                      </span>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6 mb-lg-0 mb-sm-4 mb-0 mono-margin-btn">
                <a href="javascript:void(0)" onClick={this.handleShow}>
                  <div class="product-cat-box new-mono-item-card">
                    <div class="product-cat-box-img">
                      <img
                        class="img-fluid"
                        src="assets/img/mono-cartons/New-folder/CBD-OIL-BOX.jpg"
                        alt="CBD Oil Mono Cartons"
                      />
                    </div>
                    <div class="product-cat-box-txt px-2 pb-4">
                      <p>CBD Oil Box</p>
                      <span>
                        Durable and compliant CBD oil boxes, ideal for health
                        and wellness brands offering CBD oil products.
                      </span>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6 mb-lg-0 mb-sm-4 mb-0 mono-margin-btn">
                <a href="javascript:void(0)" onClick={this.handleShow}>
                  <div class="product-cat-box new-mono-item-card">
                    <div class="product-cat-box-img">
                      <img
                        class="img-fluid"
                        src="assets/img/mono-cartons/New-folder/COSMETICS-BOX.jpg"
                        alt="Cosmetics Box Mono Cartons"
                      />
                    </div>
                    <div class="product-cat-box-txt px-2 pb-4">
                      <p>Cosmetics Box</p>
                      <span>
                        Custom cosmetics boxes perfect for makeup, skincare, and
                        beauty products, combining function with aesthetics.
                      </span>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6 mb-lg-0 mb-sm-4 mb-0 mono-margin-btn">
                <a href="javascript:void(0)" onClick={this.handleShow}>
                  <div class="product-cat-box new-mono-item-card">
                    <div class="product-cat-box-img">
                      <img
                        class="img-fluid"
                        src="assets/img/mono-cartons/New-folder/INDUSTRIAL-PRODUCTS-box.jpg"
                        alt="Industrial Products Box Mono Cartons"
                      />
                    </div>
                    <div class="product-cat-box-txt px-2 pb-4">
                      <p>Industrial Products Box</p>
                      <span>
                        Sturdy mono cartons for industrial products, offering
                        high performance and protection for complex
                        manufacturing needs.
                      </span>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6 mb-lg-0 mb-sm-4 mb-0 mono-margin-btn">
                <a href="javascript:void(0)" onClick={this.handleShow}>
                  <div class="product-cat-box new-mono-item-card">
                    <div class="product-cat-box-img">
                      <img
                        class="img-fluid"
                        src="assets/img/mono-cartons/New-folder/INNERWEAR-box.jpg"
                        alt="Innerwear Box Mono Cartons"
                      />
                    </div>
                    <div class="product-cat-box-txt px-2 pb-4">
                      <p>Innerwear Box</p>
                      <span>
                        Custom-designed innerwear mono cartons for intimate
                        apparel, providing a sleek, protective packaging
                        solution.
                      </span>
                    </div>
                  </div>
                </a>
              </div>

              <div class="col-xl-3 col-lg-4 col-sm-6 mb-lg-0 mb-sm-4 mb-0 mono-margin-btn">
                <a href="javascript:void(0)" onClick={this.handleShow}>
                  <div class="product-cat-box new-mono-item-card">
                    <div class="product-cat-box-img">
                      <img
                        class="img-fluid"
                        src="assets/img/mono-cartons/New-folder/MEDICINES-box.jpg"
                        alt="Medicines Box Mono Cartons"
                      />
                    </div>
                    <div class="product-cat-box-txt px-2 pb-4">
                      <p>Medicines Box</p>
                      <span>
                        Custom medicine boxes designed to meet the highest
                        standards of safety and compliance for pharmaceutical
                        products.
                      </span>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6 mb-lg-0 mb-sm-4 mb-0 mono-margin-btn">
                <a href="javascript:void(0)" onClick={this.handleShow}>
                  <div class="product-cat-box new-mono-item-card">
                    <div class="product-cat-box-img">
                      <img
                        class="img-fluid"
                        src="assets/img/mono-cartons/New-folder/MEDICINE-SYRUP-BOTTLE-BOX.jpg"
                        alt="Medicine Syrup Bottle Box Mono Cartons"
                      />
                    </div>
                    <div class="product-cat-box-txt px-2 pb-4">
                      <p>Medicine Syrup Bottle Box</p>
                      <span>
                        Secure and child-safe medicine syrup bottle boxes for
                        pharmaceutical companies.
                      </span>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6 mb-lg-0 mb-sm-4 mb-0 mono-margin-btn">
                <a href="javascript:void(0)" onClick={this.handleShow}>
                  <div class="product-cat-box new-mono-item-card">
                    <div class="product-cat-box-img">
                      <img
                        class="img-fluid"
                        src="assets/img/mono-cartons/New-folder/SANITARY-NAPKINS-box.jpg"
                        alt="Sanitary Napkins Box Mono Cartons"
                      />
                    </div>
                    <div class="product-cat-box-txt px-2 pb-4">
                      <p>Sanitary Napkins Box</p>
                      <span>
                        Functional and eco-friendly sanitary napkin boxes that
                        focus on hygiene & sustainability.
                      </span>
                    </div>
                  </div>
                </a>
              </div>

              <div class="col-xl-3 col-lg-4 col-sm-6 mb-lg-0 mb-sm-4 mb-0 mono-margin-btn">
                <a href="javascript:void(0)" onClick={this.handleShow}>
                  <div class="product-cat-box new-mono-item-card">
                    <div class="product-cat-box-img">
                      <img
                        class="img-fluid"
                        src="assets/img/mono-cartons/New-folder/Cookies-Box.jpeg"
                        alt="Cookies Box Mono Cartons"
                      />
                    </div>
                    <div class="product-cat-box-txt px-2 pb-4">
                      <p>Cookies Box</p>
                      <span>
                        Attractive and functional cookies & biscuit boxes made
                        using food grade boards and inks which enhances shelf
                        appeal and eye catching.
                      </span>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6 mb-lg-0 mb-sm-4 mb-0 mono-margin-btn">
                <a href="javascript:void(0)" onClick={this.handleShow}>
                  <div class="product-cat-box new-mono-item-card">
                    <div class="product-cat-box-img">
                      <img
                        class="img-fluid"
                        src="assets/img/mono-cartons/New-folder/Tissue-Box.jpeg"
                        alt="Tissue Box Mono Cartons"
                      />
                    </div>
                    <div class="product-cat-box-txt px-2 pb-4">
                      <p>Tissue Box</p>
                      <span>
                        Sturdy tissue box holder with your branding, made using
                        high quality boards and scuff-proof aqueous coated
                        multicolour printing.
                      </span>
                    </div>
                  </div>
                </a>
              </div>
              {/* <div class="col-xl-3 col-lg-4 col-sm-6 mb-lg-0 mb-sm-4 mb-0 mono-margin-btn">
                <a href="javascript:void(0)" onClick={this.handleShow}>
                  <div class="product-cat-box new-mono-item-card">
                    <div class="product-cat-box-img">
                      <img
                        class="img-fluid"
                        src="assets/img/mono-cartons/New-folder/TONIC-BOTTLE-BOX.jpg"
                        alt="Tonic Bottle Box Mono Cartons"
                      />
                    </div>
                    <div class="product-cat-box-txt px-2 pb-4">
                      <p>Tonic Bottle Box</p>
                      <span>
                        Sturdy tonic bottle boxes designed to protect delicate
                        glass bottles during transport and storage.
                      </span>
                    </div>
                  </div>
                </a>
              </div> */}
              <div class="col-xl-3 col-lg-4 col-sm-6 mb-lg-0 mb-sm-4 mb-0 mono-margin-btn">
                <a href="javascript:void(0)" onClick={this.handleShow}>
                  <div class="product-cat-box new-mono-item-card">
                    <div class="product-cat-box-img">
                      <img
                        class="img-fluid"
                        src="assets/img/mono-cartons/New-folder/Toys-box.jpeg"
                        alt="Toys Box Mono Cartons"
                      />
                    </div>
                    <div class="product-cat-box-txt px-2 pb-4">
                      <p>Toys Box</p>
                      <span>
                        Fun and eye-catching toy boxes that appeal to both
                        children and parents while keeping products safe.
                      </span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="inner-page-cont mono-cartons-inner-page-cont mono-cartons-products">
              {/* <h3 className="text-center my-3">
                    Versatile Custom Mono Cartons
                  </h3> */}
              {/* <p>We offer mono cartons for numerous sectors, including:</p>
                  <ul>
                    <li>
                      <p>
                        <b>Chocolate Box</b>
                        Attractive and durable chocolate boxes for confectionery
                        brands, ensuring freshness & style.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Hair Oil Box</b>
                        Custom-designed hair oil boxes to protect and showcase
                        your product’s quality and appeal.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Innerwear</b>
                        Custom-designed innerwear mono cartons for intimate
                        apparel, providing a sleek, protective packaging
                        solution.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Face Mask Box</b>
                        Reliable face mask boxes that maintain hygiene standards
                        while promoting your brand.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Perfume Box</b>
                        Elegant perfume boxes that elevate the luxury feel of
                        your brand, ensuring your perfume packaging is both
                        stylish and secure.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Medicines</b>
                        Custom medicine boxes designed to meet the highest
                        standards of safety and compliance for pharmaceutical
                        products.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Gloves</b>
                        Custom glove boxes for both industrial and medical uses,
                        offering a clean and protective packaging option.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Face Cream Box</b>
                        High-quality face cream boxes tailored for cosmetics
                        brands to highlight premium skincare products.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Syrup Bottle Box</b>
                        Secure and child-safe medicine syrup bottle boxes for
                        pharmaceutical companies.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Soap Box</b>
                        Eco-friendly and stylish soap boxes for natural and
                        artisanal soap brands.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>CBD Oil Box</b>
                        Durable and compliant CBD oil boxes, ideal for health
                        and wellness brands offering CBD oil products.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Sanitary Napkins</b>
                        Functional and eco-friendly sanitary napkin boxes that
                        focus on hygiene & sustainability.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Health Mix Box</b>
                        Secure and attractive health mix boxes that keep your
                        health products safe while enhancing brand recognition.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Cosmetics Box</b>
                        Custom cosmetics boxes perfect for makeup, skincare, and
                        beauty products, combining function with aesthetics.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Tonic Bottle Box</b>
                        Sturdy tonic bottle boxes designed to protect delicate
                        glass bottles during transport and storage.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Masala Box</b>
                        Flavor-preserving masala boxes for spice brands,
                        ensuring long-lasting freshness & vibrant packaging.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Industrial Products</b>
                        Sturdy mono cartons for industrial products, offering
                        high performance and protection for complex
                        manufacturing needs.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Toys Box</b>
                        Fun and eye-catching toy boxes that appeal to both
                        children and parents while keeping products safe.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Tea Box</b>
                        Premium tea boxes that preserve the freshness and aroma
                        of your tea products.
                      </p>
                    </li>
                  </ul> */}
              <div class="section-title text-center mb-1 font-weight-bolder pb-4">
                {/* <h2 className="text-theme">Why Choose Pio Packaging?</h2> */}
                <h2>Why Choose Pio Packaging?</h2>
              </div>
              <div className="row why-choose-cards">
                <div className="col-lg-4 mb-4 mb-lg-0">
                  <div class="product-cat-box mono-cartons-why-choose-icon h-100 mb-0">
                    <div class="product-cat-box-img">
                      <img
                        class="img-fluid"
                        src="assets/img/mono-cartons/Customizable-Designs.png"
                        alt="Customizable Designs Mono Cartons"
                      />
                    </div>
                    <div class="product-cat-box-txt px-2">
                      <p className="text-left">Customizable Designs</p>
                      <span>
                        Our mono cartons are fully customizable to meet the
                        unique needs of your brand, from cosmetics boxes to CBD
                        oil boxes.
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 mb-4 mb-lg-0">
                  <div class="product-cat-box mono-cartons-why-choose-icon h-100 mb-0">
                    <div class="product-cat-box-img">
                      <img
                        class="img-fluid"
                        src="assets/img/mono-cartons/High-Quality-Materials.png"
                        alt="High-Quality Materials Mono Cartons"
                      />
                    </div>
                    <div class="product-cat-box-txt px-2">
                      <p className="text-left">High-Quality Materials</p>
                      <span>
                        We use only premium materials to ensure that your hair
                        oil box, perfume box, and other packaging solutions
                        provide maximum protection and durability.
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 mb-4 mb-lg-0">
                  <div class="product-cat-box mono-cartons-why-choose-icon h-100 mb-0">
                    <div class="product-cat-box-img">
                      <img
                        class="img-fluid"
                        src="assets/img/mono-cartons/Sustainable-Packaging.png"
                        alt="Sustainable Packaging Mono Cartons"
                      />
                    </div>
                    <div class="product-cat-box-txt px-2">
                      <p className="text-left">Sustainable Packaging</p>
                      <span>
                        We offer eco-friendly options for sanitary napkins, tea
                        boxes, and more, helping your brand stay green and
                        responsible.
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <p className="mt-3">
                At Pio Packaging, we believe in delivering packaging solutions
                that not only protect your products but also elevate your brand.
                Contact us today to create the perfect custom mono cartons for
                your business.
              </p>
            </div>
          </div>
        </section>
        <button className="open-btn border-0" onClick={this.handleShow}>
          Click here for Quotes
        </button>
        <MonoCartonForm showCanvas = {this.state.show} handleClose = {this.handleClose}  />
        <Products></Products>
        <Footer></Footer>
      </div>
    );
  }
}

export default MonoCartons;
